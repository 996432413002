/**0534490959**/



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  background: 	#8A2BE2;
}

.iden{
  font-size: 1rem;
  font-family: calibri;
  margin: 0rem 1rem;
  color: white;
  padding: 20px;
}

.iden a{
  list-style: none;
  color: white;
}


.choose{
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose img{
  transition: opacity 0.5s ease-in-out
}

.choose img:hover{
  opacity: 0.8;
}

.choose p{
  text-align: center;
  padding: 20px;
  margin: 1rem;
  font-size: 1.5rem;
  font-family: Consolas;
  color: white;
}

.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 10px;
}

.btn button{
  margin: 2rem;
  border: none;
  background: rgb(61, 106, 139);
  padding: 1rem 3rem;
  font-family: calibri;
  font-size: 1.3rem;
  color: white;
  border-radius: 10px;
}

.btn button:hover{
  background: rgb(87, 119, 142);
}
